body {
  margin: 0;
  font-family: "IBM Plex Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "IBM Plex Sans", sans-serif;
}
@tailwind base;
@tailwind components;
@tailwind utilities;



@layer components {
  .hoverEffect {
    @apply cursor-pointer hover:bg-gray-200 rounded-full w-[52px] xl:w-auto xl:h-auto xl:p-3;
  }
}


.notification-btn {
  position: relative;
  border: none;
  background: none;
  cursor: pointer;
}

.notification-badge {
  position: absolute;
  top: -5px; /* Adjust position */
  right: -5px; /* Adjust position */
  background-color: red;
  color: white;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.selecteeffect {
 @apply bg-gray-200 rounded-full w-[52px] xl:w-auto xl:h-auto xl:p-3;
}

.shopping-cart__totals-wrapper .sticky-content {
  /* padding-top: 3.125rem; */
}
.sticky-content {
  position: sticky;
  top: 0;
  padding-bottom: 1px;
}

@media (min-width: 1200px){
.shopping-cart__totals {
  width: 26.25rem;
}
}

.shopping-cart__totals {
  border: 1px solid #e1e1e1;
  margin-bottom: 1.25rem;
  padding: 20px;
  max-width: 100%;
  border-radius: 5px;
}

.shopping-cart__totals>h3, .shopping-cart__totals>.h3 {
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: 1.25rem;
}

.shopping-cart__totals table {
  width: 100%;
}

.shopping-cart__totals table th {
  text-transform: uppercase;
  vertical-align: baseline;
}
.shopping-cart__totals table th, .shopping-cart__totals table td {
  border-bottom: 1px solid #e4e4e4;
  padding: 0.875rem 0;
  font-size: 0.875rem;
  font-weight: 500;
}



.shopping-cart__totals table th, .shopping-cart__totals table td {
  border-bottom: 1px solid #e4e4e4;
  padding: 0.875rem 0;
  font-size: 0.875rem;
  font-weight: 500;
}
thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}


.checkout-form .checkout__totals-wrapper .sticky-content {
  padding-top: 3.125rem;
}
.sticky-content {
  position: sticky;
  top: 0;
  padding-bottom: 1px;
}
.checkout__totals {
  border: 1px solid #222;
  margin-bottom: 1.25rem;
  padding: 2.5rem 2.5rem 0.5rem;
  width: 100%;
}
.checkout-form .checkout__payment-methods {
  border: 1px solid #e4e4e4;
  margin-bottom: 1.25rem;
  padding: 2.5rem 2.5rem 1.5rem;
  width: 100%;
}

.checkout-form .checkout__totals-wrapper .sticky-content {
  padding-top: 3.125rem;
}

.sticky-content {
  position: sticky;
  top: 0;
  padding-bottom: 1px;
}

.checkout__totals table {
  width: 100%;
}

.checkout_totals .checkout-totals th{
  border-bottom: 1px solid #e4e4e4;
  padding: 0.875rem 0;
  font-size: 0.875rem;
  font-weight: 500;
}

.checkout_totals .checkout-totals th, .checkout_totals .checkout-totals td {
  border-bottom: 1px solid #e4e4e4;
  padding: 0.875rem 0;
  font-size: 0.875rem;
  font-weight: 500;
}




.table-container {
  @apply rounded-lg overflow-x-auto w-full;
}
.thead-tr {
  @apply text-left bg-gray-50;
}
.th {
  @apply p-4 uppercase text-xs font-bold text-gray-600 text-left;
}
.td {
  @apply p-4 text-sm text-gray-700;
}
.dashboard-table {
  @apply w-full bg-gray-900 rounded-md;
}
.dashboard-tr {
  @apply border-b border-gray-800 text-left; border-bottom:  0.5px solid white
}
.dashboard-th {
  @apply p-3 uppercase text-sm font-medium text-gray-500;text-align: center;border: 0.5px solid white ;
}
.dashboard-td {
  @apply p-3  text-sm font-normal text-gray-400; border-left: 0.5px dashed white ;border-bottom: 0.5px dashed white ;text-align: center;
}